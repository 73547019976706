import { isObject } from '@oms/ui-utils';
import { Item } from './types';

export function defaultItemToString(item: Item): string {
  if (typeof item === 'string') {
    return item;
  } else if (typeof item === 'number') {
    return item.toString();
  } else if (isObject(item) && item?.label) {
    return item?.label;
  } else {
    return '';
  }
}

export const groupBy = function (
  items: any[],
  key: string
): { [key: string]: Item[] } {
  return items.reduce(function (result, item) {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
};

export const getSelected = (
  item: Item,
  selectedItem: Item,
  itemToString: (item: Item) => string
) => {
  return selectedItem
    ? itemToString(selectedItem) === itemToString(item)
    : null;
};
