import React from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { Icon, light, regular, solid } from '@oms/ui-icon';
import { IconButton, IconButtonProps } from './IconButton';
import * as S from './styles';

export type StatefulIconButtonProps = Omit<
  IconButtonProps,
  'tooltip' | 'icon'
> & {
  pressed: boolean;
  tooltip?: (state: boolean) => string;
};

export const SortButton = forwardRefWithAs<StatefulIconButtonProps, 'button'>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Sort: high to low' : 'Sort: low to high',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faSortUp : light.faSortDown}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const SortAlphaButton = forwardRefWithAs<
  StatefulIconButtonProps,
  'button'
>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Sort alphabetically: Z to A' : 'Sort alphabetically: A to Z',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faSortAlphaUp : light.faSortAlphaDown}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const SortNumericButton = forwardRefWithAs<
  StatefulIconButtonProps,
  'button'
>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Sort by price: high to low' : 'Sort by price: low to high',
      'aria-label': ariaLabel = tooltip,

      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faSortNumericUp : light.faSortNumericDown}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const SortAmountButton = forwardRefWithAs<
  StatefulIconButtonProps,
  'button'
>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Sort by price: high to low' : 'Sort by price: low to high',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faSortAmountUp : light.faSortAmountDown}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const AlarmButton = forwardRefWithAs<StatefulIconButtonProps, 'button'>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Toggle alarm: remove item' : 'Toggle alarm: add item',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faBellSlash : light.faBell}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const WatchlistButton = forwardRefWithAs<
  StatefulIconButtonProps,
  'button'
>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed
          ? 'Toggle watchlist: remove item'
          : 'Toggle watchlist: add item',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? solid.faStar : regular.faStar}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const FolderButton = forwardRefWithAs<StatefulIconButtonProps, 'button'>(
  (
    {
      pressed,
      tooltip = (pressed) =>
        pressed ? 'Toggle folder: close' : 'Toggle folder: open',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={pressed ? light.faFolderOpen : light.faFolder}
        aria-label={ariaLabel}
        tooltip={tooltip(pressed)}
        aria-pressed={pressed}
      />
    );
  },
);

export const PrintButton = forwardRefWithAs<
  Omit<IconButtonProps, 'icon'>,
  'button'
>(({ tooltip = 'Print', 'aria-label': ariaLabel = tooltip, ...props }, ref) => {
  return (
    <IconButton
      ref={ref}
      {...props}
      icon={light.faPrint}
      aria-label={ariaLabel}
      tooltip={tooltip}
    />
  );
});

export const ExcelButton = forwardRefWithAs<
  Omit<IconButtonProps, 'icon'>,
  'button'
>(
  (
    {
      tooltip = 'Download as Microsoft Excel file',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={light.faFileExcel}
        aria-label={ariaLabel}
        tooltip={tooltip}
      />
    );
  },
);

export const CalendarButton = forwardRefWithAs<
  Omit<IconButtonProps, 'icon'>,
  'button'
>(
  (
    {
      tooltip = 'Add to calendar',
      'aria-label': ariaLabel = tooltip,
      ...props
    },
    ref,
  ) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={light.faCalendarAlt}
        aria-label={ariaLabel}
        tooltip={tooltip}
      />
    );
  },
);

export const CloseButton = forwardRefWithAs<
  Omit<IconButtonProps, 'icon'>,
  'button'
>(({ 'aria-label': ariaLabel = 'Close', ...props }, ref) => {
  return (
    <IconButton
      ref={ref}
      aria-label={ariaLabel}
      {...props}
      icon={light.faTimes}
    />
  );
});

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ClearButton = forwardRefWithAs<ButtonProps, 'button'>(
  ({ 'aria-label': ariaLabel = 'Clear', ...props }, ref): JSX.Element => {
    return (
      <S.ClearButton
        ref={ref}
        aria-label={ariaLabel}
        {...props}
        type="button"
      />
    );
  },
);
export default () => <ClearButton />;

export interface ListboxButtonProps extends ButtonProps {
  isOpen?: boolean;
  as?: 'div';
}

export const ListboxButton = forwardRefWithAs<ListboxButtonProps, 'button'>(
  ({ isOpen, as, ...props }: ListboxButtonProps, ref): JSX.Element => {
    return (
      <S.SecondaryButton ref={ref} as={as} {...props} type="button">
        <Icon icon={light.faAngleDown} rotation={isOpen ? 180 : undefined} />
      </S.SecondaryButton>
    );
  },
);

export const SearchButton = forwardRefWithAs<ButtonProps, 'button'>(
  ({ 'aria-label': ariaLabel = 'Search', ...props }, ref): JSX.Element => {
    return (
      <S.SecondaryButton
        ref={ref}
        aria-label={ariaLabel}
        {...props}
        type="button"
      >
        <Icon icon={light.faSearch} size="sm" />
      </S.SecondaryButton>
    );
  },
);

interface ViewPasswordButtonProps extends StatefulIconButtonProps {
  ariaToggleButtonLabel?: { show: string; hide: string };
}
export const ViewPasswordButton = forwardRefWithAs<
  ViewPasswordButtonProps,
  'button'
>(
  (
    {
      pressed,
      ariaToggleButtonLabel = {
        show: 'Toggle visibility: show',
        hide: 'Toggle visibility: hide',
      },
      ...props
    },
    ref,
  ) => {
    return (
      <S.SecondaryButton
        ref={ref}
        {...props}
        type="button"
        aria-pressed={pressed}
        aria-label={
          pressed && ariaToggleButtonLabel
            ? ariaToggleButtonLabel?.hide
            : ariaToggleButtonLabel?.show
        }
      >
        <Icon icon={pressed ? light.faEyeSlash : light.faEye} size="sm" />
      </S.SecondaryButton>
    );
  },
);

export const InfoButton = forwardRefWithAs<{}, 'button'>(
  ({ 'aria-label': ariaLabel = 'Info', ...props }, ref): JSX.Element => {
    return (
      <S.SecondaryButton
        ref={ref}
        aria-label={ariaLabel}
        {...props}
        type="button"
      >
        <Icon icon={light.faInfoCircle} size="sm" />
      </S.SecondaryButton>
    );
  },
);
