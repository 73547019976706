import React from 'react';
import styled from '@xstyled/emotion';
import { Id } from '../types';

export const SkipLinkNavigationContainer = styled.nav``;

export const AppSkipLink = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;

  &:focus {
    padding: 4;
    position: fixed;
    top: 1rem;
    left: 1rem;
    background: #fff;
    z-index: 999;
    width: auto;
    height: auto;
    clip: auto;
    box-shadow: focus;
    border-radius: md;
  }
`;

export interface AppSkipLinksNavigationProps {
  children?: React.ReactNode;
}

export const AppSkipLinksNavigation = ({
  children = <AppSkipLink href={`#${Id.row}`}>Skip to content</AppSkipLink>,
}) => {
  return (
    <SkipLinkNavigationContainer aria-label="Skip link navigation">
      {children}
    </SkipLinkNavigationContainer>
  );
};
