import { css } from '@xstyled/emotion';

const noDefault = {};

/**
 * Component style config
 * @memberof theming
 */
export const components = {
  accordion: {
    headerHeight: '3.5rem',
    header: noDefault,
    content: noDefault,
  },

  appShell: {
    header: noDefault,
    main: noDefault,
    status: {
      info: {
        color: 'inverse-text',
        backgroundColor: 'inverse-info',
      },
      success: {
        color: 'inverse-text',
        backgroundColor: 'inverse-success',
      },
      warning: {
        color: 'inverse-text',
        backgroundColor: 'inverse-warning',
      },
      error: {
        color: 'inverse-text',
        backgroundColor: 'inverse-error',
      },
      question: {
        color: 'inverse-text',
        backgroundColor: 'inverse-question',
      },
    },
  },
  backdrop: noDefault,
  badge: {
    default: noDefault,
    variants: {
      primary: noDefault,
      secondary: noDefault,
      tertiary: noDefault,
    },
  },
  breadcrumb: {
    default: noDefault,
    item: noDefault,
  },
  button: {
    default: css`
      border-radius: md;
      &[aria-disabled='true'] {
        color: #5c5c5c;
      }
    `,
    sizes: {
      xs: css`
        padding-top: 0.1875rem;
        padding-bottom: 0.1875rem;
      `,
      sm: css`
        padding-top: 0.4375rem;
        padding-bottom: 0.4375rem;
      `,
      md: css`
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
      `,
      lg: css`
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
      `,
      xl: css`
        padding-top: 1.3125rem;
        padding-bottom: 1.3125rem;
      `,
    },
    variants: {
      primary: css`
        color: inverse-text;
        background-color: primary;
        border: sm;
        border-color: primary;
        &:hover {
          background-color: #003530;
        }
        &:active {
          background-color: #003530;
        }
      `,
      secondary: css`
        color: primary;
        border: sm;
        border-color: primary;
        &:hover {
          background-color: #ebf6f5;
        }
        &:active {
          background-color: #ebf6f5;
        }
      `,
      tertiary: css`
        color: primary;
        &:hover {
          background-color: #ebf6f5;
        }
        &:active {
          background-color: #ebf6f5;
        }
      `,
      link: css`
        padding: 0;
        color: link;
        &:hover {
          text-decoration: underline;
        }
        &:focus {
          text-decoration: underline;
        }
        &:active {
        }
      `,
      inherit: css`
        all: inherit;
      `,
      plain: {},
      buy: css`
        color: inverse-text;
        background-color: buy;
        &:hover {
          background-color: buy-hover;
        }
        &:focus {
          background-color: buy-focus;
        }
        &:active {
          background-color: buy-active;
        }
      `,
      sell: css`
        color: inverse-text;
        background-color: sell;
        &:hover {
          background-color: sell-hover;
        }
        &:focus {
          background-color: sell-focus;
        }
        &:active {
          background-color: sell-active;
        }
      `,
      danger: css`
        color: inverse-text;
        background-color: danger;
        &:hover {
          background-color: danger-hover;
        }
        &:focus {
          background-color: danger-focus;
        }
        &:active {
          background-color: danger-active;
        }
      `,
    },
  },
  card: {
    default: css`
      box-shadow: md;
      border-radius: md;
    `,
    actions: noDefault,
  },
  checkbox: {
    default: css`
      border: sm;
      border-color: selected;
      border-radius: md;

      :disabled {
        border-color: disabled;
      }
    `,
    checked: css`
      border-radius: md;
      background-color: selected;
      &::after {
        /*
        * The checkmark is made off a border
        */
        border-color: white;
      }
    `,
    sizes: {
      md: css`
        width: 1rem;
        height: 1rem;
      `,
      lg: css`
        width: 1.5rem;
        height: 1.5rem;
      `,
    },
  },
  chip: {
    default: {
      borderRadius: 'rounded',
    },
    sizes: {
      sm: css`
        height: 1.25rem;
      `,
      md: css`
        height: 1.75rem;
      `,
      lg: css`
        height: 2rem;
      `,
    },
    variants: {
      primary: css`
        background-color: surface-hover;
        border: sm;
        border-color: surface-hover;
      `,
      secondary: css`
        border: sm;
        border-color: primary;
      `,
      tertiary: noDefault,
    },
  },
  choice: {
    default: css`
      box-shadow: md;
      border-radius: md;
      border: sm;
      border-color: border;
    `,
    checked: css`
      &[data-orientation='horizontal'] {
        border-left: lg;
        border-left-color: primary;
      }
      &[data-orientation='vertical'] {
        border-bottom: lg;
        border-bottom-color: primary;
      }
    `,
    hover: noDefault,
    'focus-within': noDefault,
  },
  combobox: {
    default: noDefault,
    button: {
      default: noDefault,
      open: css`
        svg {
          transform: rotate(180deg);
        }
      `,
    },
  },
  contentSwitcher: {
    default: css`
      li {
        padding: 0.675rem;
        padding-bottom: 0.6125rem;
        min-width: 3rem;
      }
      li:hover {
        background-color: surface-hover;
      }
      &[data-orientation='horizontal'] li[aria-checked='true'] {
        font-weight: medium;
        border-bottom: md;
        border-color: selected;
      }
      &[data-orientation='vertical'] li[aria-checked='true'] {
        font-weight: medium;
        border-right: md;
        border-color: selected;
      }
    `,
    variants: {
      primary: css``,
    },
  },
  dateInput: {
    default: noDefault,
    day: {
      default: css`
        border-radius: rounded;
        &:hover {
          color: text;
          background-color: surface-hover;
        }
      `,
      today: noDefault,
      selected: css`
        background-color: selected;
        color: inverse-text;
      `,
      weekday: noDefault,
      weekend: noDefault,
      blocked: noDefault,
      inRange: css`
        background-color: in-range;
      `,
    },
  },
  descriptionList: {
    default: noDefault,
    term: noDefault,
    definition: noDefault,
  },
  dialog: {
    default: css`
      border: sm;
      border-color: border;
      border-radius: md;
      box-shadow: lg;
    `,
    header: noDefault,
    content: noDefault,
    actions: noDefault,
  },
  drawer: {
    default: css`
      box-shadow: lg;
    `,
    header: noDefault,
    content: noDefault,
    actions: noDefault,
    placement: {
      top: css`
        border-bottom-left-radius: lg;
        border-bottom-right-radius: lg;
      `,
      right: noDefault,
      bottom: css`
        border-top-left-radius: lg;
        border-top-right-radius: lg;
      `,
      left: noDefault,
    },
  },
  field: {
    label: css`
      font-size: md;
    `,
    message: css`
      font-size: sm;
    `,
    error: css`
      font-size: sm;
    `,
  },
  formError: {
    default: noDefault,
    item: noDefault,
  },
  heading: {
    default: noDefault,
    variants: {
      heading1: css`
        font-size: 4xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading2: css`
        font-size: 3xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading3: css`
        font-size: 2xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading4: css`
        font-size: xl;
        font-weight: normal;
        line-height: 1.25;
      `,
      heading5: css`
        font-size: lg;
        font-weight: bold;
        line-height: 1.25;
      `,
      heading6: css`
        font-size: md;
        font-weight: bold;
        line-height: 1.25;
      `,
      body1: css`
        font-size: sm;
        line-height: 1rem;
      `,
      body2: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      body3: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      subtitle1: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      subtitle2: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      button: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      caption: noDefault,
      overline: noDefault,
      label: noDefault,
      message: noDefault,
      error: noDefault,
    },
  },
  icon: {
    default: noDefault,
    variants: {
      primary: css`
        color: primary;
      `,
      secondary: css`
        color: secondary;
      `,
      tertiary: noDefault,
    },
  },
  iconButton: {
    default: noDefault,
    variants: {
      primary: css`
        border-radius: rounded;
        &:focus {
          background-color: icon-focus;
        }
        &:hover {
          background-color: icon-hover;
        }
        &:active {
          background-color: icon-active;
        }
      `,
      secondary: css`
        /**
        * Secondary icons are mend to be used as part of a larger 
        * component, e.g. DateInput, Combobox.
        * The icon buttons usually lack expressive focus styles.
        */
        color: secondary;
        svg,
        &::after {
          color: secondary;
        }
        &:hover::before,
        &:hover::after {
          background-color: surface-hover;
        }
      `,
      tertiary: noDefault,
    },
    sizes: {
      sm: css`
        height: 1.75rem;
        width: 1.75rem;
      `,
      md: css`
        height: 2rem;
        width: 2rem;
      `,
      lg: css`
        height: 2.5rem;
        width: 2.5rem;
      `,
    },
  },
  interactiveList: {
    /**
     * It's recommended to keep these styles similar to `table`
     */
    default: css`
      [data-row-group] {
        line-height: 1.125rem;
        font-size: md;
      }
      [data-row] {
      }
      [data-row]:focus,
      [data-row]:hover {
        background-color: surface-hover;
      }
      [data-row]:active,
      [data-row][aria-checked='true'] {
        color: inverse-text !important;
        background-color: selected;
        [data-trend] {
          color: inverse-text;
        }
      }
      [data-heading-cell],
      [data-cell] {
        padding: 0.675rem;
        padding-bottom: 0.6125rem;
        height: 2.75rem;
      }
    `,
    variants: {
      primary: css`
        [data-row] {
          [data-heading-cell] {
            font-weight: medium;
            background-color: surface-1;
            border-top: sm;
            border-bottom: sm;
            border-color: border;
          }
          [data-cell] {
            border-top: sm;
            border-bottom: sm;
            border-color: border;
          }
        }
      `,
      secondary: noDefault,
      tertiary: noDefault,
    },
  },
  keyboard: {
    key: {
      default: noDefault,
    },
  },
  loading: {
    /** dot background-color */
    backgroundColor: 'primary',
  },
  marquee: {
    default: noDefault,
  },
  menu: {
    default: noDefault,
    item: {
      default: noDefault,
      highlighted: css`
        background-color: surface-hover;
      `,
    },
    separator: noDefault,
    anchor: noDefault,
    link: noDefault,
    checkbox: noDefault,
    radio: noDefault,
  },
  notification: {
    default: css`
      border: sm;
      border-radius: md;
      border-left: lg;
      border-color: border;
    `,
    info: css`
      border-left-color: info;
    `,
    success: css`
      border-left-color: success;
    `,
    error: css`
      border-left-color: error;
    `,
    warning: css`
      border-left-color: warning;
    `,
    question: css`
      border-left-color: question;
    `,
  },
  /**
   * Panel is shared by Select, Combobox, DateInput, Menu
   * Popover, InfoBubble,
   */
  panel: css`
    color: text;
    background-color: surface-1;
    border-radius: md;
    box-shadow: menu;
    border: sm;
    border-color: border;
  `,
  popover: {
    panel: noDefault,
    arrow: noDefault,
    actions: noDefault,
  },
  progressBar: {
    trail: css`
      border-radius: md;
      background-color: #c8d1da;
    `,
    stroke: css`
      background-color: primary;
    `,
  },
  radio: {
    default: css`
      border: sm;
      border-color: selected;

      :disabled {
        border-color: disabled;
      }

      & + [role='presentation'] {
        /** 
        * This makes sure the element with role="presentation" 
        * is visible when hovered 
        */
        background-color: selected;
      }
    `,
    checked: css`
      /** This targets the element with role="presentation" */
      padding: 0.2rem;
      background-color: selected;
    `,
  },
  rating: {
    default: noDefault,
    leafs: noDefault,
    bars: noDefault,
    stars: noDefault,
  },
  search: {
    default: noDefault,
  },
  segment: {
    wrapper: css`
      border: sm;
      border-color: primary;
      &:first-of-type {
        border-top-left-radius: md;
        border-bottom-left-radius: md;
      }
      &:last-of-type {
        border-top-right-radius: md;
        border-bottom-right-radius: md;
      }
      &:not(:last-of-type) {
        border-right: none;
      }
    `,
    default: css`
      color: primary;
    `,
    checked: css`
      background-color: #008d7f33;
      fill: primary;
    `,
  },
  select: {
    default: noDefault,
    listBox: noDefault,
    item: {
      default: noDefault,
      focus: css`
        background-color: #ebf6f5;
      `,
      highlighted: css`
        background-color: surface-hover;
      `,
      selected: css`
        color: selected;
        > svg {
          color: selected;
        }
      `,
      selectedDisabled: css`
        background-color: #0071eb33;
      `,
    },
    groupHeader: css`
      font-size: sm;
      color: text-label;
      border-top: sm;
      border-color: border;
      font-weight: bold;
      text-transform: uppercase;
    `,
  },
  separator: noDefault,
  slider: {
    default: noDefault,
    sizes: {
      sm: css`
        height: 0.125rem;
      `,
      md: css`
        height: 0.25rem;
      `,
      lg: css`
        height: 0.5rem;
      `,
    },
    track: css`
      background-color: border;
      &.slider__track-0,
      &.slider__range-track-1 {
        background-color: secondary;
      }
    `,
    thumb: css`
      background-color: white;
      border-radius: rounded;
      box-shadow: md;
    `,
    value: css`
      color: white;
      background-color: secondary;
      border-radius: md;
    `,
  },
  spinner: {
    /** track color */
    borderColor: 'border',
    /** segment color */
    borderTopColor: 'secondary',
  },
  stepper: {
    default: css``,
    item: css``,
  },
  switch: {
    default: css`
      border: sm;
      border-color: secondary;
      border-radius: rounded;
      height: 1rem;
      width: 2.5rem;
      & + [data-indicator] {
        width: 1.25rem;
        height: 1.25rem;
        left: -0.125rem;
        top: -0.125rem;
        border-radius: rounded;
        background-color: white;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      }
    `,
    checked: css`
      background-color: secondary;
      & + [data-indicator] {
        transform: translateX(1.4rem);
      }
    `,
  },
  table: {
    default: css`
      thead {
      }

      thead th,
      tbody th {
        font-weight: medium;
      }

      tbody {
        line-height: 1.125rem;
        font-size: md;
      }

      tbody tr:hover {
        background-color: surface-hover;
      }

      tbody tr[data-selected='true'],
      tbody tr[aria-selected='true'] {
        background-color: selected;
      }
    `,
    variants: {
      normal: {
        'th, td': {
          borderTop: 'sm',
          borderBottom: 'sm',
          borderColor: 'border',
        },
      },
      keyValue: {
        'th, td': {
          borderTop: 'sm',
          borderBottom: 'sm',
          borderColor: 'border',
        },
      },
      flipped: {
        'th, td': {
          borderLeft: 'sm',
          borderColor: 'border',
        },
      },
    },
    densities: {
      dense: css`
        td,
        th {
          padding: 0.455rem;
          padding-bottom: 0.4125rem;
          height: 2rem;
        }
      `,
      normal: css`
        td,
        th {
          padding: 0.675rem;
          padding-bottom: 0.6125rem;
          height: 2.75rem;
          /** This td has an irregular shape */
          &[data-type='symbol'] {
            padding-bottom: 0.925rem;
          }
        }
      `,
      spacious: css`
        td,
        th {
          padding: 1.175rem;
          padding-bottom: 1.1125rem;
          height: 3.5rem;
        }
      `,
    },
    rowSelected: noDefault,
    rowExpanded: noDefault,
    stickyHeader: {
      zIndex: 1,
      backgroundColor: '#FFFFFFEE',
    },
    toggleRowsButton: css`
      display: table-cell;
      vertical-align: middle;
      color: primary;
      height: 2.5rem;
      border-bottom: sm;
      border-color: border;
      &:hover,
      &:focus {
        background-color: surface-hover;
        text-decoration: underline;
      }
    `,
    cells: {
      sortableHeader: css`
        &:focus,
        &:hover {
          color: secondary;
        }
      `,
      link: css`
        &:hover,
        &:focus {
          color: link;
          text-decoration: underline;
        }
      `,
      trend: {
        pos: {
          color: 'text-pos',
        },
        neg: {
          color: 'text-neg',
        },
      },
      flash: {
        pos: css`
          background-color: pos;
          color: inverse-text;
        `,
        neg: css`
          background-color: neg;
          color: inverse-text;
        `,
        flat: css`
          background-color: flat;
          color: text;
        `,
      },
    },
  },
  tabs: {
    default: noDefault,
    variants: {
      primary: css`
        flex: 1;
        padding: 3 1;
        font-size: md;
        color: text;
        border-bottom: sm;
        border-bottom-color: border;
        &:focus {
          background-color: surface-hover;
        }
        &:hover {
          background-color: surface-hover;
        }
        &[aria-selected='true'] {
          color: secondary;
          border-bottom-color: secondary;
        }
      `,
      secondary: css`
        padding: 1 2;
        color: secondary;
        border-radius: md;
        & + * {
          margin-left: 2;
        }
        &:focus {
          background-color: secondary-focus;
        }
        &:hover {
          background-color: secondary-hover;
        }
        &[aria-selected='true'] {
          background-color: secondary;
        }
        &:focus,
        &:hover,
        &[aria-selected='true'] {
          color: white;
        }
      `,
    },
  },
  text: {
    default: noDefault,
    variants: {
      heading1: css`
        font-size: 4xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading2: css`
        font-size: 3xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading3: css`
        font-size: 2xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading4: css`
        font-size: xl;
        font-weight: normal;
        line-height: 1.25;
      `,
      heading5: css`
        font-size: lg;
        font-weight: bold;
        line-height: 1.25;
      `,
      heading6: css`
        font-size: md;
        font-weight: bold;
        line-height: 1.25;
      `,
      body1: css`
        font-size: sm;
        line-height: 1rem;
      `,
      body2: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      body3: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      subtitle1: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      subtitle2: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      button: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      caption: noDefault,
      overline: noDefault,
      label: noDefault,
      message: noDefault,
      error: noDefault,
    },
    highlighted: css`
      color: selected;
      font-weight: bold;
    `,
  },
  textInput: {
    input: css`
      /** avoid weird iOS zoom issues */
      font-size: lg;

      @media only screen and (min-width: 26.25em) {
        font-size: md;
      }
    `,
    wrapper: {
      /**
       * The wrapping element should reflect
       * the state of the input.
       * */
      default: css`
        height: 2.5rem;
        border: sm;
        border-color: border;
        border-radius: sm;
        background-color: input;
      `,
      hover: css`
        border-color: contrast-border;
      `,
      focus: css`
        background-color: input-focus;
        border-color: border-focus;
        box-shadow: focus;
      `,
      error: css`
        border-color: border-error;
      `,
      focusError: css`
        background-color: input-focus;
        border-color: border-error;
        box-shadow: focus-error;
      `,
      disabled: css`
        color: text-disabled;
        background-color: #f2f4f6;
        cursor: not-allowed;
      `,
    },
  },
  toolbar: {
    default: css`
      padding: 3;
      border: sm;
      border-color: border;
      background-color: surface-1;
      &:focus,
      &:hover {
        background-color: surface-hover;
      }

      &:first-of-type {
        border-right: none;
        border-top-left-radius: md;
        border-bottom-left-radius: md;
        padding-left: 4;
      }
      &:last-of-type {
        border-left: none;
        border-top-right-radius: md;
        border-bottom-right-radius: md;
        padding-right: 4;
      }
    `,
  },
  tooltip: {
    default: css`
      color: inverse-text;
      font-size: sm;
      background-color: #333;
      opacity: 0.95;
      padding: 1 2;
      border-radius: md;
    `,
  },
};
