import React, { useState } from 'react';
import { useSelect } from 'downshift';
import { usePopper } from 'react-popper-2';
import {
  forwardRefWithAs,
  noop,
  splitProps,
  systemProps,
  useForkRef,
} from '@oms/ui-utils';
import { TextInput } from '@oms/ui-text-input';
import { Icon, light } from '@oms/ui-icon';
import { RenderItem } from './RenderItem';
import { SelectProps } from './types';
import { defaultItemToString, getSelected, groupBy } from './utils';
import * as S from './styles';

/**
 * A collapsible (single) select listbox widget that
 * is functionally similar to an HTML select input.
 */
export const Select = forwardRefWithAs<SelectProps, 'button'>(function Select(
  {
    value = '',
    onChange = noop,
    onBlur,
    onFocus,
    disabled,
    id,
    name,
    labelId,
    placeholder = 'Select',
    'aria-describedby': describedBy,
    'aria-invalid': invalid,
    'aria-errormessage': errorMessage,
    required,
    items = [],
    itemToString = defaultItemToString,
    renderItem: ItemRenderer = RenderItem,
    onSelectedItemChange,
    defaultSelectedItem,
    groupByKey,
    groupToString = (group: string) => group,
    ...props
  },
  ref,
) {
  const [system] = splitProps(props, systemProps as any);

  /*
   *  Using useState instead of useRef. useState effectively provides a callback ref,
   *  which triggers an update in usePopper as the ref is assigned.
   */
  const [element, registerElement] = useState<HTMLElement | null>(null);
  const forkedRef = useForkRef(registerElement, ref);
  const [popper, registerPopper] = useState<HTMLElement | null>(null);
  const { styles, attributes, forceUpdate } = usePopper(element, popper, {
    placement: 'bottom-start',
  });

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    toggleButtonId: id,
    labelId,
    items: groupByKey
      ? Object.values(groupBy(items, groupByKey)).flat()
      : items,
    itemToString,
    defaultHighlightedIndex: 0,
    onSelectedItemChange,
    defaultSelectedItem,
    onStateChange: ({ type, selectedItem }: any) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.FunctionSelectItem:
          if (selectedItem) {
            onChange(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  React.useEffect(() => {
    if (isOpen) {
      forceUpdate?.();
    }
  }, [isOpen, forceUpdate]);

  return (
    <S.Container {...system}>
      <TextInput
        data-use-placeholder={!selectedItem}
        {...getToggleButtonProps({
          ref: forkedRef,
          type: 'button',
          onFocus,
          name,
          value: selectedItem ? itemToString(selectedItem) : placeholder,
          'aria-describedby': describedBy,
          'aria-invalid': invalid,
          'aria-errormessage': errorMessage,
        })}
        rightElement={
          <div style={{ marginRight: '1rem' }}>
            <Icon
              icon={light.faAngleDown}
              rotation={isOpen ? 180 : undefined}
              color="secondary"
            />
          </div>
        }
      />
      <S.ListBox
        isOpen={isOpen}
        {...getMenuProps({
          onBlur,
          ref: registerPopper,
          style: styles.popper,
          ...attributes.popper,
        })}
      >
        {!isOpen
          ? null
          : groupByKey
          ? Object.entries(groupBy(items, groupByKey)).reduce(
              (
                result = { sections: [], itemIndex: 0 },
                [group, items],
                groupIndex,
              ) => {
                result.sections.push(
                  <S.Group key={groupIndex}>
                    <S.GroupHeader>{groupToString(group)}</S.GroupHeader>
                    <S.GroupList>
                      {items.map((item, itemIndex) => {
                        const isSelected = getSelected(
                            item,
                            selectedItem,
                            itemToString,
                          ),
                          index = result.itemIndex++,
                          isHighlighted = highlightedIndex === index;
                        return (
                          <ItemRenderer
                            key={`${groupToString(group)}-${itemIndex}`}
                            item={item}
                            isHighlighted={isHighlighted}
                            isSelected={isSelected}
                            itemToString={itemToString}
                            ItemContainer={S.ItemContainer}
                            {...getItemProps({
                              item,
                              index,
                            })}
                          />
                        );
                      })}
                    </S.GroupList>
                  </S.Group>,
                );
                return result;
              },
              { sections: [] as JSX.Element[], itemIndex: 0 },
            ).sections
          : items.map((item, index) => {
              const isSelected = getSelected(item, selectedItem, itemToString),
                isHighlighted = highlightedIndex === index;

              return (
                <ItemRenderer
                  key={`select-item-${index}`}
                  item={item}
                  isHighlighted={isHighlighted}
                  isSelected={isSelected}
                  itemToString={itemToString}
                  ItemContainer={S.ItemContainer}
                  {...getItemProps({
                    item,
                    index,
                  })}
                />
              );
            })}
      </S.ListBox>
    </S.Container>
  );
});
