import { css } from '@xstyled/emotion';

export const reset = css`
  /**
  * 1. Prevent padding and border from affecting element width
  * https://goo.gl/pYtbK7
  * 2. Correct the line height in all browsers.
  * 3. Prevent adjustments of font size after orientation changes in iOS.
  */
  html {
    height: 100%;
    box-sizing: border-box; /* 1 */
    line-height: 1.15; /* 2 */
    -webkit-text-size-adjust: 100%; /* 3 */
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
  }

  /**
 * Remove the margin in all browsers.
 */
  body {
    margin: 0;
  }

  /**
 * Render the main element consistently in IE.
 */
  main {
    display: block;
  }
  /**
  * Add the correct display in IE 10.
  */
  [hidden] {
    display: none;
  }
`;

// merge with button base
export const button = css`
  /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
  *    Remove the inheritance of text transform in Firefox.
  */
  appearance: none; /* 1 */
  background: transparent; /* 1 */
  padding: 0; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  &::before,
  &::after {
    box-sizing: border-box;
  }
  ::-moz-focus-inner {
    border: 0 !important;
  }
  :focus {
    outline: none !important; /* important for firefox */
    box-shadow: focus;
  }
  /**
 * Remove the inner border and padding in Firefox.
 */
  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
`;

type Pending = { isPending: boolean };
export const isPending = (props: Pending) => css`
  cursor: ${props.isPending ? 'progress' : 'pointer'};
`;

export const textButton = css`
  margin: 0;
  flex-shrink: 0;
  text-align: center;
  width: auto;
  font-family: body;
  font-weight: normal;
  line-height: none;
  border-color: transparent;
  border-width: 0;
  border-style: solid;
  color: inherit;
  user-select: none;
  :disabled,
  &[aria-disabled='true'] {
    color: inverse-text;
    background-color: disabled;
    border-color: disabled;
    cursor: not-allowed;
    &:focus,
    &:hover {
      background-color: disabled;
    }
  }
`;

export const uploadButton = css`
  /**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to inherit in Safari.
 */
  &input[type='button']::-webkit-file-upload-button,
  &button::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }
`;

export const anchor = css`
  /**
 * Remove the gray background on active links in IE 10.
 */
  color: inherit;
  text-decoration: none;
  background-color: transparent;
`;

export const input = css`
  /**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

  appearance: none;
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  padding: 0;
  color: inherit;
  outline: none !important;
  overflow: visible;
  border: none;
  &:focus {
    outline: none !important; /* important for firefox */
  }
`;

export const checkbox = css`
  appearance: none;
  outline: none !important;
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  &:focus {
    outline: none !important;
  }
`;

export const number = css`
  /**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    height: auto;
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const search = css`
  /**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
  & [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  /**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
  &[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &[type='search']::-webkit-search-cancel-button {
    display: none;
  }
`;

export const textarea = css`
  /**
 * Remove the default vertical scrollbar in IE 10+.
 */
  &textarea {
    resize: vertical;
    overflow: auto;
    font-family: inherit;
  }
`;

export const select = css`
  /**
 * Remove the default vertical scrollbar in IE 10+.
 */
  resize: vertical;
  overflow: auto;
`;

export const media = css`
  /**
* Make replaced elements display: block by default as that's
* the behavior you want almost all of the time. Inspired by
* CSS Remedy, with svg added as well.
*
* https://github.com/mozdevs/cssremedy/issues/14
*/
  border-style: none;
  overflow: hidden;
  display: block;
  vertical-align: baseline;
  vertical-align: middle;
  min-width: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
`;

export const separator = css`
  /**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
  border-color: border;
  border-width: 0;
  border-style: solid;
`;

export const box = css`
  border-color: border;
  border-width: 0;
  border-style: solid;
`;

export const list = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const listItem = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const legend = css`
  /**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from fieldset elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    fieldset elements in all browsers.
 */
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
`;

export const fieldset = css`
  /**
 * Correct the padding in Firefox.
 */
  padding: 0;
`;

export const table = css`
  border-collapse: collapse;
  border-spacing: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  tbody,
  thead,
  tfoot,
  caption,
  tr,
  td,
  th {
    border-collapse: collapse;
    border-spacing: 0;
    min-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
`;

export const text = css`
  padding: 0;
  &small,
  small {
    font-size: 80%;
  }
  b,
  &b,
  strong,
  &strong {
    font-weight: bolder;
  }
  h1,
  &h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
`;

export const pre = css`
  /**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd em font sizing in all browsers.
 */
  &pre,
  &code,
  &kbd,
  &samp {
    font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
`;

export const abbr = css`
  /**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
  abbr[title] &abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }
`;

export const borderBox = css`
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
  * {
    box-sizing: border-box;
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
  padding: 0;
  margin: 0;
`;

export const borderWidth = css`
  border-color: border;
  border-width: 0;
  border-style: solid;
`;

export const uppercase = (props: any) =>
  props.uppercase
    ? css`
        text-transform: uppercase;
      `
    : ``;

export const capitalize = (props: any) =>
  props.uppercase
    ? css`
        text-transform: capitalize;
      `
    : ``;

const overflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const panel = css`
  position: absolute;
  max-height: 30rem;
  color: text;
  background-color: surface-2;
  padding: 0;
  margin: 0;
  z-index: 80;
  :focus {
    outline: none !important;
  }
`;

export const mixins = {
  reset,
  button,
  textButton,
  uploadButton,
  anchor,
  input,
  checkbox,
  number,
  search,
  textarea,
  select,
  media,
  separator,
  box,
  list,
  listItem,
  legend,
  fieldset,
  table,
  text,
  pre,
  abbr,
  borderBox,
  borderWidth,
  isPending,
  uppercase,
  capitalize,
  overflowEllipsis,
  flexCenter,
  panel,
};
