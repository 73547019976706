import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  useCallback,
  ReactElement,
  ReactNode,
  Children,
  cloneElement,
} from 'react';
import { useBreakpoints } from '@xstyled/emotion';
import { useMediaQuery, warning, Breakpoints } from '@oms/ui-utils';
import './polyfill';

type MediaQueries =
  | 'base'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | 'isMobile'
  | 'isTablet'
  | 'isDesktop'
  | 'isLargeDesktop'
  | 'reducedMotion'
  | 'darkMode';

type Queries = {
  orientation: 'portrait' | 'landscape';
  base: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  '2xl': boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  reducedMotion: boolean;
  darkMode: boolean;
};

export interface MediaContextProps extends Queries {
  matches: (mq: keyof Omit<Queries, 'orientation'>) => boolean;
}

const MediaContext = createContext<MediaContextProps>({
  orientation: window.orientation === 0 ? 'portrait' : 'landscape',
  base: true,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  '2xl': false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isLargeDesktop: false,
  reducedMotion: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
  darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
  matches: (_a: string) => false,
});
MediaContext.displayName = 'MediaContext';

export const useMedia = (): MediaContextProps => useContext(MediaContext);

export const MediaProvider = ({ children }: { children: React.ReactNode }) => {
  const breakpoints = useBreakpoints() as { [key in Breakpoints]?: any };

  const minBp = useMemo(
    () =>
      Object.values(breakpoints).map((breakpoint: any) => ({
        minWidth: breakpoint,
      })),
    []
  );
  const base = useMediaQuery({ minWidth: 0 });
  const sm = useMediaQuery(minBp[1]);
  const md = useMediaQuery(minBp[2]);
  const lg = useMediaQuery(minBp[3]);
  const xl = useMediaQuery(minBp[4]);
  const xxl = useMediaQuery(minBp[5]);
  const reducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    window.orientation === 0 ? 'portrait' : 'landscape'
  );
  useEffect(() => {
    const handler = () => {
      setOrientation(window.orientation === 0 ? 'portrait' : 'landscape');
    };
    window.addEventListener('orientationchange', handler, true);
    return () => window.removeEventListener('orientationchange', handler);
  }, []);

  const queries = {
    orientation,
    base,
    sm,
    md,
    lg,
    xl,
    '2xl': xxl,
    isMobile: base && !md,
    isTablet: md && !lg,
    isDesktop: lg,
    isLargeDesktop: xxl,
    reducedMotion,
    darkMode,
  };
  const queriesAndMatches = {
    ...queries,
    matches: (mq: keyof Omit<Queries, 'orientation'>) => {
      warning(
        queries[mq] === undefined,
        '[useMedia]',
        `Unsupported media query: ${mq}`
      );
      return queries[mq];
    },
  };
  return (
    <MediaContext.Provider value={queriesAndMatches}>
      {children}
    </MediaContext.Provider>
  );
};

/**
 * A hook that returns the value matching the current media query
 * @example const columns = useResponsiveValue({ isMobile:4, isTablet:6, isDesktop:8 })
 */
export const useResponsiveValue = (
  config: { [key in MediaQueries]?: any }
): any | undefined => {
  const { matches } = useContext(MediaContext);
  const entree = Object.entries(config).find(([key]) =>
    matches(key as keyof Omit<Queries, 'orientation'>)
  ) || [undefined, undefined];

  return entree[1];
};

/**
 * A component that returns the first child matching the current media query
 * @example
 *      <Media>
 *        <Table isDesktop>
 *        <List isTablet>
 *        <Card default>
 *      </Media>
 */
export const Media = ({ children }: { children: ReactNode }) => {
  const { matches, ...queries } = useContext(MediaContext);

  const findMatch = useCallback(
    (props) =>
      Object.keys(props).filter((prop) => queries[prop as keyof Queries])[0],
    [queries]
  );

  const match = useMemo(
    () =>
      Children.map(
        children as ReactElement | ReactElement[],
        (child: ReactElement) => {
          const matched = findMatch(child.props);
          return matched ? cloneElement(child, { [matched]: undefined }) : null;
        }
      ) || [],
    [children, findMatch]
  );

  const defaultChild = useMemo(
    () =>
      Children.map(
        children as ReactElement | ReactElement[],
        (child: ReactElement) =>
          child.props.default === true
            ? cloneElement(child, { default: undefined })
            : null
      ),
    [children]
  );
  warning(
    !defaultChild,
    '[Media]',
    'Each <Media /> Component should have a default child. For example: <Child default />'
  );

  return match.length > 0 ? <>{match}</> : <>{defaultChild}</>;
};
