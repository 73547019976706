import React, { createContext, useContext, ReactNode } from 'react';
import { noop } from '@oms/ui-utils';
import { Backdrop } from '@oms/ui-backdrop';
import { CloseButton } from '@oms/ui-icon-button';
import { useId } from '@oms/ui-utils';
import * as R from 'reakit/Dialog';
import * as S from './styles';

type DrawerPosition = 'top' | 'right' | 'bottom' | 'left' | 'sheet';

type DrawerState = {
  dialog: any;
  placement: DrawerPosition;
};

const DrawerContext = createContext<DrawerState>({} as any);
DrawerContext.displayName = 'DrawerContext';
export const useDrawer = () => R.useDialogState({ animated: true }); //  (config?: DialogState) => R.useDialogState({ animated: true, ...config });

export interface DrawerProps
  extends Omit<R.DialogProps, 'unstable_initialFocusRef'> {
  placement?: DrawerPosition;
  onDismiss?: R.DialogProps['hide'];
  initialFocusRef?: R.DialogProps['unstable_initialFocusRef'];
}

export function Drawer({
  onDismiss = noop,
  initialFocusRef,
  children,
  placement = 'right',
  ...dialog
}: DrawerProps) {
  const hide = () => {
    dialog && dialog.hide && dialog.hide();
    onDismiss();
  };
  const BackdropWrapper = dialog.modal ? Backdrop : React.Fragment;
  const dialogState = { ...dialog, hide };
  return (
    <DrawerContext.Provider value={{ dialog: dialogState, placement }}>
      <BackdropWrapper {...(dialog.modal ? dialog : {})}>
        <R.Dialog {...dialogState} unstable_initialFocusRef={initialFocusRef}>
          {(dialogProps) => (
            <S.Dialog as="section" {...dialogProps} placement={placement}>
              {children}
            </S.Dialog>
          )}
        </R.Dialog>
      </BackdropWrapper>
    </DrawerContext.Provider>
  );
}

export interface DrawerCloseButtonProps {
  'aria-label'?: string;
  title?: string;
}

export function DrawerCloseButton({
  'aria-label': ariaLabel = 'Close modal',
  title,
}: DrawerCloseButtonProps) {
  const { dialog } = useContext(DrawerContext);
  return (
    <CloseButton aria-label={ariaLabel} title={title} onClick={dialog.hide} />
  );
}

export interface DrawerHeaderProps {
  children: ReactNode;
  action?: ReactNode;
  ariaCloseButtonLabel?: string;
}

export function DrawerHeader({
  children,
  action,
  ariaCloseButtonLabel = 'Close modal',
}: DrawerHeaderProps) {
  const { placement } = useContext(DrawerContext);
  const id = useId();
  if (placement === 'left') {
    return (
      <S.Header aria-labelledby={id}>
        <S.Action justifyContent="flex-end">{action}</S.Action>
        <S.Text id={id}>{children}</S.Text>
        <S.Action>
          <DrawerCloseButton aria-label={ariaCloseButtonLabel} />
        </S.Action>
      </S.Header>
    );
  }
  return (
    <S.Header aria-labelledby={id}>
      <S.Action>
        <DrawerCloseButton />
      </S.Action>
      <S.Text id={id}>{children}</S.Text>
      <S.Action justifyContent="flex-end">{action}</S.Action>
    </S.Header>
  );
}

/**
 * @implements Box
 */
export const DrawerContent = S.Content as typeof S.Content;

/**
 * @implements Box
 */
export const DrawerActions = S.Actions; // as typeof Stack;

export interface DrawerDisclosureProps extends R.DialogDisclosureProps {}
/**
 * Accessible Disclosure component that controls visibility of a section of content (Drawer)
 */
export const DrawerDisclosure = R.DialogDisclosure;
