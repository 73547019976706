import React, { ReactNode } from 'react';
import { noop, SystemProps, forwardRefWithAs } from '@oms/ui-utils';
import { Spinner } from '@oms/ui-icon';
import * as S from './styles';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'buy'
  | 'sell'
  | 'danger';

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ButtonProps extends SystemProps {
  children: ReactNode;
  as?: any;
  size?: Sizes;
  /**
   * Content left, e.g. <Icon />
   * @deprecated
   * */
  leftAddon?: ReactNode;
  /**
   * Content right, e.g. <Icon />
   * @deprecated
   * */
  rightAddon?: ReactNode;
  /** Content left, e.g. <Icon /> */
  leftElement?: ReactNode;
  /** Content right, e.g. <Icon /> */
  rightElement?: ReactNode;
  variant?: ButtonVariants;
  /** State to indicate a process is busy.
   * @example ```tsx
   * <Button type="submit" isPending={isSubmitting} />
   * ```
   */
  isPending?: boolean;
  disabled?: boolean;
}

export const Button = forwardRefWithAs<ButtonProps, 'button'>(function Button(
  {
    children,
    leftAddon = '',
    leftElement = leftAddon,
    rightAddon,
    rightElement = rightAddon,
    variant = 'primary',
    size = 'md',
    disabled,
    onClick,
    title,
    'aria-label': ariaLabel,
    isPending,
    as,
    ...props
  },
  ref,
) {
  const showLeft = !!leftElement || (isPending && !rightElement);
  return (
    <S.Button
      ref={ref}
      as={as}
      type={as ? undefined : 'button'}
      {...props}
      variant={variant}
      buttonSize={size}
      onClick={disabled ? noop : onClick}
      aria-disabled={disabled}
      title={disabled ? `${title} disabled` : title}
      aria-label={disabled ? `${ariaLabel} disabled` : ariaLabel}
      isPending={isPending}
    >
      {showLeft && (
        <S.Addon data-left="" mr={3}>
          {isPending ? <Spinner /> : leftElement}
        </S.Addon>
      )}
      {children}
      {rightElement && (
        <S.Addon data-right="" ml={3}>
          {isPending ? <Spinner /> : rightElement}
        </S.Addon>
      )}
    </S.Button>
  );
});
