import React, { useEffect } from 'react';
import styled, { css } from '@xstyled/emotion';
import { breakpoints } from '@xstyled/system';
import {
  useDrawer,
  Drawer,
  DrawerDisclosure,
  DrawerContent,
} from '@oms/ui-drawer';
import { useMedia } from '@oms/ui-media';
import { Icon, light } from '@oms/ui-icon';
import { IconButton } from '@oms/ui-icon-button';
import { Stack } from '@oms/ui-stack';
import { CloseButton } from './CloseButton';

const HamburgerButton = styled.button`
  background-color: #00adf0;
  border: none;
  width: 3.75rem;
  min-height: 3.75rem;
  color: white;
  grid-area: drawer;
  ${breakpoints({
    base: css`
      margin: 0 0 -1px auto;
    `,
    md: css`
      margin: 0 3 -1px 0;
    `,
  })}
`;

const Content = styled(DrawerContent)`
  background-color: #202e41;
  background-image: radial-gradient(circle at top left, #354c67, #202e41 720px);
`;

const DrawerButton = ({ placement, ...props }: any) => {
  if (placement === 'left') {
    return (
      <DrawerDisclosure as={HamburgerButton} aria-label="menu" {...props}>
        <Icon icon={light.faBars} size="2x" color="inverse-text" />
      </DrawerDisclosure>
    );
  }
  return (
    <DrawerDisclosure
      as={IconButton}
      icon={light.faBars}
      aria-label="menu"
      {...props}
    />
  );
};

export interface AppNavigationDrawerProps {
  /** Navigation links */
  children: React.ReactNode;
  /** E.g. sign in/out button */
  actions?: React.ReactNode;
  placement?: 'left' | 'right';
}

export function AppNavigationDrawer({
  children,
  actions,
  placement,
}: AppNavigationDrawerProps) {
  const media = useMedia();
  const drawer = useDrawer();

  useEffect(() => {
    const el = document.getElementById(drawer.baseId);
    const handler = (e: Event) => {
      const clickedElement = e.target as HTMLElement;
      if (
        clickedElement.tagName.toLowerCase() === 'a' ||
        clickedElement.nodeName.toLowerCase() === 'a'
      ) {
        drawer.hide();
      }
    };
    el?.addEventListener('click', handler);
    return () => el?.removeEventListener('click', handler);
  }, []);

  const _placement = placement || media.matches('md') ? 'left' : 'right';

  return (
    <>
      <DrawerButton {...drawer} placement={_placement} />
      <Drawer {...drawer} placement={_placement}>
        <Content>
          <CloseButton
            aria-label="Close menu"
            icon={light.faTimes}
            onClick={drawer.hide}
            style={{ marginLeft: _placement === 'left' ? 'auto' : undefined }}
          />
          {typeof children === 'function' ? children({ drawer }) : children}
          {actions && (
            <Stack mx={-3} mb={-3} mt="auto" p={3} orientation="horizontal">
              {typeof actions === 'function' ? actions({ drawer }) : actions}
            </Stack>
          )}
        </Content>
      </Drawer>
    </>
  );
}
