import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from '@xstyled/emotion';
import { variant } from '@xstyled/system';
import { forwardRefWithAs, useConstant } from '@oms/ui-utils';
import { StatusIcon, StatusIntentions } from '@oms/ui-icon';
import { Box, BoxProps } from '@oms/ui-box';
import { Text } from '@oms/ui-text';
import { CloseButton } from './CloseButton';
import createPersistedState from 'use-persisted-state';

type KeyOptions = {
  /**
   * Changes icon and styles of the notification
   */
  status?: StatusIntentions;
  title: React.ReactElement | string;
  id?: string | number;
  children: React.ReactNode;
};

export type AppStatusProps = BoxProps &
  KeyOptions & {
    /** Place in the application the notification originates from. E.g. Trader */
    sender?: string;
    /**
     * When provided, this function will be called when the status message is dismissed.
     */
    onClose?: () => void | undefined;
    type?: 'polite' | 'assertive';
  };

const Container = styled.div`
  padding: 4;
  ${variant({
    key: 'appShell.status',
    prop: 'status',
  })}
`;

const createKey = ({ status, title, id, children }: KeyOptions) =>
  JSON.stringify({
    status,
    title: renderToString(<>{title}</>),
    id,
    children: renderToString(<>{children}</>),
  });

const usePersistedState = createPersistedState('@oms/app-shell-status');

export const AppStatus = forwardRefWithAs<AppStatusProps, 'div'>(
  function AppStatus(
    { children, title, status = 'info', onClose, type = 'polite', ...props },
    ref,
  ) {
    const key = useConstant(() =>
      createKey({
        status,
        title,
        id: props.id,
        children,
      }),
    );

    const [dismissed, setDismissed] = usePersistedState({
      [key]: false,
    });

    const _onClose = () => {
      onClose?.();
      setDismissed({ [key]: true });
    };

    if (dismissed[key]) {
      return null;
    }

    return (
      <Container
        ref={ref}
        {...props}
        type={type}
        status={status}
        role="status"
        aria-live={type}
      >
        <Box display="flex">
          <StatusIcon status={status} color="inverse-text" />
          <Box flex={1} ml={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Text variant="heading6" color="inverse-text">
                {title}
              </Text>
              <CloseButton onClick={_onClose} size="sm" color="inverse-text" />
            </Box>
            <Text mt={2} color="inverse-text">
              {children}
            </Text>
          </Box>
        </Box>
      </Container>
    );
  },
);
