import React from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import {
  Provider,
  useBreakpoints,
  Context,
  useResizeObserver as useResizeObserverLib,
  Observe,
  useResizeObserverEntry as useResizeObserverEntryLib,
} from '@envato/react-breakpoints';
import { BreakpointsOptions, ResizeObserverEntry, ObserveProps } from './types';

export const ElementQueryContext = (Context as unknown) as React.Context<ResizeObserverEntry | null>;

export const ElementQueryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Provider ponyfill={ResizeObserver}>{children}</Provider>;
};

export function useElementQuery(
  options: BreakpointsOptions,
  injectResizeObserverEntry?: ResizeObserverEntry
) {
  return useBreakpoints(options as any, injectResizeObserverEntry as any) as [
    any,
    any
  ];
}

export function useResizeObserver(options?: any) {
  return useResizeObserverLib(options) as [React.Ref<any>, ResizeObserverEntry];
}

export function useResizeObserverEntry(
  injectResizeObserverEntry?: ResizeObserverEntry
) {
  return useResizeObserverEntryLib(
    injectResizeObserverEntry as any
  ) as ResizeObserverEntry | null;
}

export function ElementObserver(props: ObserveProps) {
  return (<Observe {...(props as any)} />) as JSX.Element | null;
}
