import merge from 'deepmerge';
import { theme } from './theme';
import { Theme } from './types';

/**
 * Theme creator
 * @param config Theme config
 * @returns theme
 */
export const create = (config: Theme = {}): Theme => {
  return merge(theme, config);
};
