import React, { createContext, useMemo, ReactNode, useContext } from 'react';
import {
  forwardRefWithAs,
  useId,
  splitProps,
  systemProps,
  wrapEvent,
  noop,
  SystemProps,
} from '@oms/ui-utils';
import { light } from '@oms/ui-icon';
import { IconButton } from '@oms/ui-icon-button';
import * as R from 'reakit/Menu';
import * as S from './styles';

const MenuContext = createContext<any>({});
MenuContext.displayName = 'MenuContext';
export const useMenu = (): R.MenuStateReturn => useContext(MenuContext);

export interface MenuProps extends R.MenuInitialState {
  disclosure?: React.ReactElement;
  children: ReactNode;
}

/**
 * @namespace
 */
export const Menu = forwardRefWithAs<MenuProps, 'div'>(function Menu(
  {
    placement = 'auto-start',
    disclosure = <IconButton icon={light.faEllipsisV} aria-label="Menu" />,
    children,
    ...props
  },
  ref,
) {
  const [rest, config] = splitProps(props, systemProps as any);
  const menu = R.useMenuState({ placement, ...config });
  const value = useMemo(() => menu, Object.values(menu));
  const id = useId();
  return (
    <MenuContext.Provider value={value}>
      <R.MenuButton {...menu} id={id} {...disclosure.props}>
        {(menuButtonProps) => React.cloneElement(disclosure, menuButtonProps)}
      </R.MenuButton>
      <R.Menu
        ref={ref}
        {...menu}
        {...rest}
        aria-labelledby={disclosure.props.id || id}
        as={S.Menu}
      >
        {children}
      </R.Menu>
    </MenuContext.Provider>
  );
});

export interface MenuItemProps extends SystemProps {
  onSelect?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

/**
 * @memberof Menu
 */
export const MenuItem = forwardRefWithAs<MenuItemProps, 'li'>(function MenuItem(
  { children, onSelect = noop, as = 'div', ...props },
  ref,
) {
  const menu = useMenu();
  const menuItemProps = R.useMenuItem(menu);
  return (
    <S.MenuItem
      {...props}
      ref={ref}
      as={as}
      {...menuItemProps}
      onClick={wrapEvent(
        onSelect,
        wrapEvent(menu.hide, menuItemProps.onClick as any),
      )}
    >
      <>{children}</>
    </S.MenuItem>
  );
});

/**
 * @memberof Menu
 */
export const MenuSeparator = forwardRefWithAs<any, 'li'>(function MenuSeparator(
  { children, ...props },
  ref,
) {
  const menu = useMenu();
  return (
    <R.MenuSeparator ref={ref} {...props} {...menu} as={S.MenuSeparator}>
      {children}
    </R.MenuSeparator>
  );
});
