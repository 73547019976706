import React from 'react';
import styled, { Global, css } from '@xstyled/emotion';
import { useAppShell, useAppShellObserver } from '../AppShellContext';
import { LeftRegion, RightRegion } from '../Portals';
import { Id, AppLayoutProps } from '../types';
import { ASIDE_WIDTH } from '../constants';

export const AppContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;

export const Row = styled.box`
  display: flex;
  flex: 1;
`;

export const ContentColumn = styled.box`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: surface-2;
`;

/**
 * `Layout approximation`
 *
 * | col1              | col2        | col3           |
 * | -------------    | ------------- | ------------- |
 * | [--------------- | statusBar     | ---------------] |
 * | [--------------- | navigationBar | ---------------] |
 * | [- leftRegion -] | contentArea   | [- rightRegion -]   |
 * | [- leftRegion -] | [- footer -]  | [- rightRegion -]   |
 * */
export function AppLayout({
  skipLinks = <div />,
  status = <div />,
  navigationBar = <header />,
  footer = <footer />,
  children,
}: AppLayoutProps) {
  const { top } = useAppShell();
  const { appRef, topRef } = useAppShellObserver();

  return (
    <>
      <Global
        /**
         * There's a bit of css trickery involved to make this work, so bear with me:
         * =========================================================================
         * DOM Structure:
         *  [LeftAside]
         *    [content]
         *    [span data-apply-width]
         *  [ContentColumn]
         *  [RightAside
         *    [content]
         *    [span data-apply-width]
         * ]
         *  When the sheet/aside content is *NOT* hidden we set the width of it's sibling element (span[data-apply-width]).
         *  The width of this sentinel element is animated and helps set the dimensions of
         *  the Aside nodes (#${Id.rightAside}, #${Id.leftAside}), the dimensions of ContentArea are affected by the dimensions of the Aside nodes .
         *
         *  Below the min-width of 52.5em we don't set the width of the sibling element, thus Aside nodes have a width of 0.
         *  Now the sheet/content will simply overflow it's parent container (#${Id.rightAside}) and render (partially) on top of ContentArea.
         *
         *  On the smallest of screen sizes the aside content should *NOT* render inside of the Aside nodes; It should render layered on top of ContentArea when used as a Sheet or in the flow of
         *  content inside of ContentArea.
         */
        styles={css`
          #${Id.rightAside}, #${Id.leftAside} {
            position: relative;

            span[data-apply-width] {
              height: 0px;
              display: block;
              width: 0;
              will-change: width;
              transition: width 200ms ease-in-out;
            }

            @media only screen and (min-width: 52.5em) {
              div:not([hidden]) ~ span[data-apply-width],
              aside:not([hidden]) ~ span[data-apply-width] {
                width: ${ASIDE_WIDTH};
              }
            }
          }
        `}
      />
      {skipLinks}
      <AppContainer id={Id.app} ref={appRef}>
        <Top id={Id.top} ref={topRef}>
          {status && React.cloneElement(status, { id: Id.status })}
          {navigationBar &&
            React.cloneElement(navigationBar, {
              id: Id.header,
            })}
        </Top>
        <Row
          id={Id.row}
          style={{
            paddingTop: top?.height,
          }}
        >
          <LeftRegion.Target />
          <ContentColumn>
            <ContentArea id={Id.contentArea}>{children}</ContentArea>
            {footer && React.cloneElement(footer, { id: Id.footer })}
          </ContentColumn>
          <RightRegion.Target /*portalAttributes={{ 'aria-label': 'modal' }}*/
          />
        </Row>
      </AppContainer>
    </>
  );
}
